import { useEffect, useRef, useState } from "react";

import "./ThreeCubeHome.css";
import "./ThreeCubeHomeMobile.css";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import React from "react";
import Input from "./Input";


const ThreeCubeHome = () => {

  const initialFormData = {
    name: "",
    email: "",
    contact: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const { name, email, contact, message } = formData;
  const [disable, setDisable] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const empLinkRef = useRef<HTMLAnchorElement>(null);
  const corpLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const isMobile = width <= 768;
  const updateFormData = (newData: { [key: string]: string }) => {
    setFormData((prev) => ({ ...prev, ...newData }));
  };

  const submitEnquiry = async () => {
  };

  const handleClickAway = () => {
    setShowMenu(false);
  };

  return (
    <>
      {isMobile ? (
        <div className="website-div">
          <div className="div-2">
            <div className="div-3">
              <div className="div-5">
                <img
                  alt=""
                  loading="lazy"
                  src="/home/tctpl_logo.svg"
                  className="img"
                />
                <span>3Cube</span>
              </div>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className="div-4">
                  <img
                    alt=""
                    loading="lazy"
                    src="/home/menu_icon.svg"
                    className="img-2"
                    onClick={() => {
                      setShowMenu((prev) => !prev);
                    }}
                  />
                  <div className={`wrapper ${showMenu ? "open-menu" : ""}`}>
                    <div
                      className="employee-login"
                      onClick={() => {
                        empLinkRef.current?.click();
                      }}
                    >
                      <img
                        alt=""
                        loading="lazy"
                        src="/home/img21.svg"
                        className="img-2"
                      />
                      <div className="label">
                        <a
                          ref={empLinkRef}
                          className="remove-anchor-styles"
                          // href="/employee/login"
                        >
                          Employee Login
                        </a>
                      </div>
                    </div>
                    <div
                      className="corporate-login"
                      onClick={() => {
                        corpLinkRef.current?.click();
                      }}
                    >
                      <a
                        ref={corpLinkRef}
                        className="remove-anchor-styles"
                        // href="/corporate/login"
                      >
                        Are you a corporate looking to sign up?
                      </a>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </div>
            <div className="div-6">
              <div className="div-7">EMPOWERING BUSINESSES</div>
              <div className="div-8">
                Through its innovative & business friendly financial tools.
              </div>
              <div className="div-9">
                <div className="div-10">
                  <img
                    alt=""
                    loading="lazy"
                    srcSet="/home/img_urls_1.png 100w, /home/img_urls_2.png 200w, /home/img_urls_3.png 400w, /home/img_urls_4.png 800w, /home/img_urls_5.png 1200w, /home/img_urls_6.png 1600w, /home/img_urls_7.png 2000w, /home/img_urls_8.png"
                    className="img-3"
                  />
                  <img
                    alt=""
                    loading="lazy"
                    srcSet="/home/img_urls_9.png 100w, /home/img_urls_10.png 200w, /home/img_urls_11.png 400w, /home/img_urls_12.png 800w, /home/img_urls_13.png 1200w, /home/img_urls_14.png 1600w, /home/img_urls_15.png 2000w, /home/img_urls_16.png"
                    className="img-4"
                  />
                </div>
                <div className="div-11">
                  <img
                    alt=""
                    loading="lazy"
                    srcSet="/home/img_urls_17.png 100w, /home/img_urls_18.png 200w, /home/img_urls_19.png 400w, /home/img_urls_20.png 800w, /home/img_urls_21.png 1200w, /home/img_urls_22.png 1600w, /home/img_urls_23.png 2000w, /home/img_urls_24.png"
                    className="img-5"
                  />
                  <img
                    alt=""
                    loading="lazy"
                    srcSet="/home/img_urls_25.png 100w, /home/img_urls_26.png 200w, /home/img_urls_27.png 400w, /home/img_urls_28.png 800w, /home/img_urls_29.png 1200w, /home/img_urls_30.png 1600w, /home/img_urls_31.png 2000w, /home/img_urls_32.png"
                    className="img-6"
                  />
                </div>
                <div className="div-12">
                  <img
                    alt=""
                    loading="lazy"
                    srcSet="/home/img_urls_33.png 100w, /home/img_urls_34.png 200w, /home/img_urls_35.png 400w, /home/img_urls_36.png 800w, /home/img_urls_37.png 1200w, /home/img_urls_38.png 1600w, /home/img_urls_39.png 2000w, /home/img_urls_40.png"
                    className="img-7"
                  />
                  <img
                    alt=""
                    loading="lazy"
                    srcSet="/home/img_urls_41.png 100w, /home/img_urls_42.png 200w, /home/img_urls_43.png 400w, /home/img_urls_44.png 800w, /home/img_urls_45.png 1200w, /home/img_urls_46.png 1600w, /home/img_urls_47.png 2000w, /home/img_urls_48.png"
                    className="img-8"
                  />
                </div>
              </div>
              <div className="div-13">
                <img
                  alt=""
                  loading="lazy"
                  src="/home/img8.svg"
                  className="img-9"
                />
                <div className="div-14">Scroll to see more sections</div>
              </div>
            </div>
            <div className="div-15">
              <div className="div-16">
                <div className="div-17">Our Purpose</div>
                <div className="div-18">
                  Empower business to engage effectively with their employees
                  with our innovative employee friendly financial programs.
                </div>
                <div className="div-19">
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      letterSpacing: "-0.14px",
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      letterSpacing: "-0.14px",
                      color: "rgba(0, 101, 168, 1)",
                    }}
                  >
                    Help{" "}
                  </span>
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      letterSpacing: "-0.14px",
                    }}
                  >
                    businesses retain talent”
                  </span>
                  <br />
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      letterSpacing: "-0.14px",
                    }}
                  >
                    “Enable businesses to be the{" "}
                  </span>
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      letterSpacing: "-0.14px",
                      color: "rgba(0, 101, 168, 1)",
                    }}
                  >
                    employer of choice
                  </span>
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      letterSpacing: "-0.14px",
                    }}
                  >
                    ”
                  </span>
                  <br />
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      letterSpacing: "-0.14px",
                    }}
                  >
                    “Financial Inclusion”
                  </span>
                </div>
              </div>
              <img
                alt=""
                loading="lazy"
                src="/home/img9.svg"
                className="img-10"
              />
            </div>
            <div className="div-20">Our Product</div>
            <div className="div-21">
              <div className="div-22">
                <img
                  alt=""
                  loading="lazy"
                  src="/svg/cards_bg.svg"
                  className="img-11"
                />
                <div className="div-23" style={{ paddingBottom: "52px" }}>
                  <div className="div-24">Early Pay</div>
                  <div className="div-25">
                    Quick and easy access to salary anytime at low costs
                  </div>
                </div>
              </div>
              {/* <div className="div-21"> */}
              <div className="div-22">
                <img
                  alt=""
                  loading="lazy"
                  src="/svg/cards_bg.svg"
                  className="img-11"
                />
                <div className="div-23">
                  <div className="div-24">Streamline Your Financial Goals</div>
                  <div className="div-25">
                    <ul>
                      <li>Participate in capital market instruments</li>
                      <li>Retirement planning</li>
                      <li>Risk protection</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* </div> */}
              {/* <div className="div-21"> */}
              <div className="div-22">
                <img
                  alt=""
                  loading="lazy"
                  src="/svg/cards_bg.svg"
                  className="img-11"
                />
                <div className="div-23">
                  <div className="div-24">Asset Leasing</div>
                  <div className="div-25">
                    <ul>
                      <li>
                        Access to specified assets under a short tenure leasing
                        program.
                      </li>
                      <li>
                        Helps facilitate remote and flexible work environment
                      </li>
                      <li>
                        Enhance operational efficiency and cost effectiveness
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="div-31">
              &quot;Unlocking financial freedom through smart asset leasing and
              early pay solutions.&quot;
            </div>
            <img
              alt=""
              loading="lazy"
              src="/home/img12.svg"
              className="img-13"
            />
            <div className="div-32">
              <div className="div-33">Management Team</div>
              <div className="div-34">
                <div className="div-35">
                  With over 35 years of banking experience with multinational
                  banks, the promoters and their team offer a comprehensive
                  range of financial services, aiming to exceed client
                  expectations and to remain a trusted partner in the
                  ever-evolving financial landscape.
                </div>
                <div className="div-36">
                  <br />
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      fontWeight: "600",
                      fontStyle: "italic",
                      fontSize: "11px",
                      color: "rgba(0, 101, 168, 1)",
                    }}
                  >
                    Chetan Dhanuka-
                  </span>
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      fontWeight: "500",
                      color: "rgba(96, 94, 94, 1)",
                    }}
                  >
                    Co-Founder & Director. Chartered Accountant & Company
                    Secretary, Held leadership positions at Citibank, ICICI &
                    American Express Bank.
                  </span>
                  <br />
                  <br />
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      fontWeight: "600",
                      fontStyle: "italic",
                      fontSize: "11px",
                      color: "rgba(0, 101, 168, 1)",
                    }}
                  >
                    Cinu Jones-
                  </span>
                  <span
                    style={{
                      fontFamily:
                        "Josefin Sans, -apple-system, Roboto, Helvetica,sans-serif",
                      fontWeight: "500",
                      color: "rgba(96, 94, 94, 1)",
                    }}
                  >
                    Co-Founder & Director. Chartered Accountant. Held Leadership
                    positions at Citibank, Standard Chartered Bank.
                  </span>
                </div>
                <img
                  alt=""
                  loading="lazy"
                  src="/home/img13.svg"
                  className="img-14"
                />
              </div>
            </div>
            {/* <div className="div-37">
              <div className="div-38">
                Got any questions? Don't hesitate to get in touch.
              </div>
              <div className="div-39">
                <img
                  alt=""
                  loading="lazy"
                  src="/home/img14.png"
                  className="img-15"
                />
                <div className="div-40">Contact Us</div>
                <div className="div-41">
                  <Input
                    textColor="white"
                    type="text"
                    placeholder="Full Name"
                    valueKey="name"
                    inputValue={name}
                    onChangeAction={updateFormData}
                  />
                </div>
                <div className="div-42">
                  <Input
                    textColor="white"
                    type="text"
                    placeholder="Email Address"
                    valueKey="email"
                    inputValue={email}
                    onChangeAction={updateFormData}
                  />
                </div>
                <div className="div-43">
                  <Input
                    textColor="white"
                    type="text"
                    placeholder="Contact number"
                    valueKey="contact"
                    isNumberType={true}
                    inputValue={contact}
                    onChangeAction={updateFormData}
                  />
                </div>
                <div className="div-44">
                  <Input
                    textColor="white"
                    type="text"
                    placeholder="Message"
                    valueKey="message"
                    inputValue={message}
                    onChangeAction={updateFormData}
                  />
                </div>
                <div className="div-45" onClick={submitEnquiry}>
                  Submit
                </div>
                <div className="div-46">Terms & Privacy Acknowledgement</div>
              </div>
              <div className="div-47">
                <img
                  alt=""
                  loading="lazy"
                  src="/home/img20.png"
                  className="img-16"
                />
              </div>
            </div> */}
            <div className="div-48">
              <div className="div-49">
                <div className="div-50">
                  <div className="div-51">
                    <img
                      alt=""
                      loading="lazy"
                      src="/home/tctpl_logo.svg"
                      className="img-17"
                    />
                    <div className="div-52">3Cube</div>
                  </div>
                  <div className="div-53">
                    © 2024 3cube. All rights reserved.
                  </div>
                </div>
                <div className="div-54">
                  <div className="div-55">Get in touch</div>
                  <div className="div-56">
                    THREE CUBE TECHNOLOGIES PVT LTD, Ground Floor, Sivanandam
                    Apartments, # 1 B&C, East Park Road, Pulla Avenue, Shenoy
                    Nagar, Chennai - 600023
                  </div>
                  <div className="div-57">+91 9884932221</div>
                  <div className="div-58">care@3cube.in</div>
                </div>
                <div className="div-59">
                  <div className="div-60">Learn More</div>
                  <div className="div-61">About Us</div>
                  <div className="div-62">Services</div>
                  <div className="div-63">Team</div>
                  <div className="div-64">Terms of Use</div>
                  <div className="div-65">Privacy policy</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="website-div">
          <div className="div-2">
            <div
              className="div-3"
              onClick={() => {
                corpLinkRef.current?.click();
              }}
            >
              <a
                ref={corpLinkRef}
                className="remove-anchor-styles"
                // href="/corporate/login"
              >
                Are you a corporate looking to sign up?
              </a>
            </div>
            <div className="div-4">
              <img
                alt=""
                loading="lazy"
                src="/home/tctpl_logo.svg"
                className="img"
              />
              <div className="div-5">3Cube</div>
            </div>
            <div
              className="div-6"
              onClick={() => {
                empLinkRef.current?.click();
              }}
            >
              <img
                alt=""
                loading="lazy"
                src="/home/img21.svg"
                className="img-2"
              />
              <div className="div-7">
                <a
                  ref={empLinkRef}
                  className="remove-anchor-styles"
                  // href="/employee/login"
                >
                  Employee Login
                </a>
              </div>
            </div>
          </div>
          <div className="div-8">
            <div className="div-9">
              <div className="div-10">
                <div className="column">
                  <div className="div-11">
                    <div className="div-12">
                      EMPOWERING
                      <br />
                      BUSINESSES
                    </div>
                    <div className="div-13">
                      Through its innovative & business friendly financial
                      tools.
                    </div>
                  </div>
                </div>
                <div className="column-2">
                  <div className="div-14">
                    <div className="div-15">
                      <div className="div-16">
                        <div className="column-3">
                          <img
                            alt=""
                            loading="lazy"
                            src="/home/img22.png"
                            className="img-3"
                          />
                        </div>
                        <div className="column-4">
                          <img
                            alt=""
                            loading="lazy"
                            src="/home/img23.png"
                            className="img-4"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="div-17">
                      <div className="div-18">
                        <div className="column">
                          <img
                            alt=""
                            loading="lazy"
                            src="/home/img24.png"
                            className="img-5"
                          />
                        </div>
                        <div className="column-5">
                          <img
                            alt=""
                            loading="lazy"
                            src="/home/img25.png"
                            className="img-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="div-19">
                      <div className="div-20">
                        <div className="column-6">
                          <img
                            alt=""
                            loading="lazy"
                            src="/home/img26.png"
                            className="img-7"
                          />
                        </div>
                        <div className="column-7">
                          <img
                            alt=""
                            loading="lazy"
                            src="/home/img27.png"
                            className="img-8"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-21">
              <img
                alt=""
                loading="lazy"
                src="/home/img28.svg"
                className="img-9"
              />
              <div className="div-22">Scroll to see more sections</div>
            </div>
          </div>
          <div className="div-23">
            <div className="div-24">
              <div className="column-8">
                <div className="div-25">
                  <div className="div-26">Our Purpose</div>
                  <div className="div-27">
                    Empower businesses to engage effectively with their
                    employees with our innovative employee friendly financial
                    programs.
                  </div>
                  <div className="div-28">
                    <span
                      style={{
                        fontFamily:
                          "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                      }}
                    >
                      “
                    </span>
                    <span
                      style={{
                        fontFamily:
                          "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                        color: "rgba(0, 101, 168, 1)",
                      }}
                    >
                      Help{" "}
                    </span>
                    <span
                      style={{
                        fontFamily:
                          "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                      }}
                    >
                      businesses retain talent”
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily:
                          "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                      }}
                    >
                      “Enable businesses to be the{" "}
                    </span>
                    <span
                      style={{
                        fontFamily:
                          "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                        color: "rgba(0, 101, 168, 1)",
                      }}
                    >
                      employer of choice
                    </span>
                    <span
                      style={{
                        fontFamily:
                          "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                      }}
                    >
                      ”
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily:
                          "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                      }}
                    >
                      “Financial Inclusion”
                    </span>
                  </div>
                </div>
              </div>
              <div className="column-9">
                <img
                  alt=""
                  loading="lazy"
                  src="/home/img29.svg"
                  className="img-10"
                />
              </div>
            </div>
          </div>
          <div className="div-29">Our Products</div>
          <div className="div-30">
            <div className="div-31">
              <div className="column-10">
                <div className="div-37">
                  <div className="div-38">
                    <img
                      alt=""
                      loading="lazy"
                      src="/svg/cards_bg.svg"
                      className="img-11"
                    />
                    <div className="div-39" style={{ textAlign: "left" }}>
                      Early Pay
                    </div>
                    <div
                      className="div-40"
                      style={{ paddingBottom: "30%", paddingRight: "25px" }}
                    >
                      Quick and easy access to salary anytime at low costs
                    </div>
                  </div>
                  {/* <div className="div-36"></div> */}
                </div>
              </div>
              <div className="column-11">
                <div className="div-37">
                  <div className="div-38">
                    <img
                      alt=""
                      loading="lazy"
                      src="/svg/cards_bg.svg"
                      className="img-11"
                    />
                    <div className="div-39">
                      Streamline Your Financial Goals
                    </div>
                    <div className="div-40" style={{ paddingBottom: "14%" }}>
                      <ul>
                        <li>Participate in capital market instruments</li>
                        <li>Retirement planning</li>
                        <li>Risk protection</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-12">
                <div className="div-38">
                  <img
                    alt=""
                    loading="lazy"
                    src="/svg/cards_bg.svg"
                    className="img-11"
                  />
                  <div className="div-39">Asset Leasing</div>
                  <div className="div-40">
                    <ul>
                      <li>
                        Access to specified assets under a short tenure leasing
                        program.
                      </li>
                      <li>
                        Helps facilitate remote and flexible work environment
                      </li>
                      <li>
                        Enhance operational efficiency and cost effectiveness
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-42">
            <div className="div-43">
              <div className="column">
                <div className="div-44">
                  &quot;Unlocking financial freedom through smart asset leasing
                  and early pay solutions.&quot;
                </div>
              </div>
              <div className="column-13">
                <img
                  alt=""
                  loading="lazy"
                  src="/home/img32.svg"
                  className="img-13"
                />
              </div>
            </div>
          </div>
          <div className="div-45">
            <div className="div-46">
              <div className="div-47">
                <div className="column-14">
                  <div className="div-48">
                    <div className="div-49">Management Team</div>
                    <div className="div-50">
                      With over 35 years of banking experience with
                      multinational banks, the promoters and their team offer a
                      comprehensive range of financial services, aiming to
                      exceed client expectations and to remain a trusted partner
                      in the ever-evolving financial landscape.
                    </div>
                    <div className="div-51">
                      <br />
                      <span
                        style={{
                          fontFamily:
                            "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                          fontWeight: "500",
                          fontStyle: "italic",
                          color: "rgba(0, 101, 168, 1)",
                        }}
                      >
                        Chetan Dhanuka
                      </span>
                      <span
                        style={{
                          fontFamily:
                            "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                        }}
                      >
                        -
                      </span>
                      <span
                        style={{
                          fontFamily:
                            "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                          fontSize: "18px",
                        }}
                      >
                        Co-Founder & Director. Chartered Accountant & Company
                        Secretary, Held leadership positions at Citibank, ICICI
                        & American Express Bank.
                      </span>
                      <br />
                      <br />
                      <span
                        style={{
                          fontFamily:
                            "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                          fontWeight: "500",
                          fontStyle: "italic",
                          color: "rgba(0, 101, 168, 1)",
                        }}
                      >
                        Cinu Jones
                      </span>
                      <span
                        style={{
                          fontFamily:
                            "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                        }}
                      >
                        -
                      </span>
                      <span
                        style={{
                          fontFamily:
                            "Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif",
                          fontSize: "18px",
                        }}
                      >
                        Co-Founder & Director. Chartered Accountant. Held
                        Leadership positions at Citibank, Standard Chartered
                        Bank.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="column-15">
                  <img
                    alt=""
                    loading="lazy"
                    src="/home/img33.svg"
                    className="img-14"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="div-52">Contact Us</div>
          <div className="div-53">
            <div className="div-54">
              <div className="column-16">
                <div className="div-55">
                  <img
                    alt=""
                    loading="lazy"
                    src="/home/img34.png"
                    className="img-15"
                  />
                  <div className="div-56">
                    Got any questions? Don't hesitate to get in touch.
                  </div>
                  <div className="div-57">
                    <Input
                      textColor="white"
                      type="text"
                      placeholder="Full Name"
                      valueKey="name"
                      inputValue={name}
                      onChangeAction={updateFormData}
                    />
                  </div>
                  <div className="div-58">
                    <Input
                      textColor="white"
                      type="text"
                      placeholder="Email Address"
                      valueKey="email"
                      inputValue={email}
                      onChangeAction={updateFormData}
                    />
                  </div>
                  <div className="div-59">
                    <Input
                      textColor="white"
                      type="text"
                      placeholder="Contact number"
                      valueKey="contact"
                      isNumberType={true}
                      inputValue={contact}
                      onChangeAction={updateFormData}
                    />
                  </div>
                  <div className="div-60">
                    <Input
                      textColor="white"
                      type="text"
                      placeholder="Message"
                      valueKey="message"
                      inputValue={message}
                      onChangeAction={updateFormData}
                    />
                  </div>
                  <div className="div-61" onClick={submitEnquiry}>
                    Submit
                  </div>
                  <div className="div-62">Terms & Privacy Acknowledgement</div>
                </div>
              </div>
              <div className="column-17">
                <div className="div-63">
                  <img
                    alt=""
                    loading="lazy"
                    src="/home/img35.svg"
                    className="img-16"
                  />
                  <div className="div-64">
                    <img
                      alt=""
                      loading="lazy"
                      src="/home/img36.svg"
                      className="img-17"
                    />
                    <div className="div-65">
                      THREE CUBE TECHNOLOGIES PVT LTD, Ground Floor, Sivanandam
                      Apartments, # 1 B&C, East Park Road, Pulla Avenue, Shenoy
                      Nagar, Chennai - 600023
                    </div>
                  </div>
                  <div className="div-66">
                    <img
                      alt=""
                      loading="lazy"
                      src="/home/img37.svg"
                      className="img-18"
                    />
                    <div className="div-67">+91 9884932221</div>
                  </div>
                  <div className="div-68">
                    <img
                      alt=""
                      loading="lazy"
                      src="/home/img38.svg"
                      className="img-19"
                    />
                    <div className="div-69">care@3cube.in</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-70">
            <div className="div-71">
              <div className="div-72">
                <div className="div-73">
                  <img
                    alt=""
                    loading="lazy"
                    src="/home/tctpl_logo.svg"
                    className="img-20"
                  />
                  <div className="div-74">3Cube</div>
                </div>
                <div className="div-75">© 2024 3cube. All rights reserved.</div>
              </div>
              <div className="div-76">
                <div className="div-77">Get in touch</div>
                <div className="div-78">
                  THREE CUBE TECHNOLOGIES PVT LTD, Ground Floor, Sivanandam
                  Apartments, # 1 B&C, East Park Road, Pulla Avenue, Shenoy
                  Nagar, Chennai - 600023
                </div>
                <div className="div-79">+91 9884932221</div>
                <div className="div-80">care@3cube.in</div>
              </div>
              <div className="div-81">
                <div className="div-82">Learn More</div>
                <div className="div-83">About Us</div>
                <div className="div-84">Services</div>
                <div className="div-85">Team</div>
                <div className="div-86">Terms of Use</div>
                <div className="div-87">Privacy policy</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ThreeCubeHome;
