import {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import classes from "./Input.module.css";
import "./input.css";

import dayjs from "dayjs";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import FormHelperText from "@mui/material/FormHelperText";
import VerifiedIcon from "@mui/icons-material/Verified";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import InfoIcon from "@mui/icons-material/Info";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
} from "@mui/material";

import { Height } from "@mui/icons-material";
import { BorderAll, BorderColor } from "@mui/icons-material";
import React from "react";

const verifyObject = {
  emp_approve_required: {
    className: "emp-approval-required",
    hoverText: "Employee approval required",
  },
  admin_verify_required: {
    className: "admin-verify-required",
    hoverText: "Admin verification required",
  },
  admin_verified: {
    className: "admin-verified",
    hoverText: "Verified by Admin",
  },
  checker_verify_required: {
    className: "checker-verify-required",
    hoverText: "Verified by Admin, Checker verification required",
  },
  checker_verified: {
    className: "all-verified",
    hoverText: "Verified by Admin & Checker",
  },
};

const Input = (props: any) => {
  //#region properties
  const {
    isAdmin,
    isChecker,
    textColor,
    isSignupPage,
    variant,
    inputStyle,
    additionalClass,
    mapKey,
    type,
    inputRef,
    applicationTypeSelect,
    autoCompleteOptions,
    subLabel,
    showCopyCheckbox,
    showRadiobutton,
    radioButtonValue,
    secondValidation,
    radioAction,
    checkboxLabel,
    globalError,
    copyInputValue,
    readOnly,
    tableInputWidth = "50%",
    ShowAdorment,
    selectOptions,
    id,
    label,
    focused,
    fieldNote,
    hideInfoIcon,
    placeholder,
    inputValue,
    receivedUpload,
    acceptedFileTypes,
    onChangeAction,
    calendarFormat,
    calendarOpenWith,
    dateFormat,
    deleteUploadedFile,
    saveFile,
    triggerOtp,
    isOtpValidated,
    setErrorValidation,
    valueKey,
    pathKey,
    isRequired,
    disabled,
    copyCheckboxClicked,
    allowToEditCheckBox,
    tableType,
    handleWidth,
    noOptionsText,
    disableUpload,
    helperText,
    definitionText,
    showVerify,
    readOnlyVerify,
    allowVerifyUpdate,
    hasFieldChanged,
    isAdminVerified,
    isCheckerVerified,
    verifyField,
    adminVerifyKey,
    checkerVerifyKey,
    verifyStatus,
    infoText,
    isError,
    ignoreValidation,
    validationType,
    externalError,
    isNumberType,
    setIsEmailValid,
    tillToday = true,
    disablePast,
    setIsMobileValid,
    updateOnChange,
    ignoreStateUpdate,
    updateOnBlur,
    updateStateOnChange,
    aliasName,
    updateValidations,
  } = props;

  const [value, setValue] = useState<any>(inputValue || "");
  const [showHint, setShowHint] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isPercentage, setIsPercentage] = useState<string>(
    radioButtonValue ? "amount" : "percentage"
  );
  const dateRef = useRef(inputValue);
  const [showVerifyPopup, setShowVerifyPopup] = useState<any>(false);

  //#endregion

  //#region methods
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    setValue(inputValue);
    showRadiobutton &&
      setIsPercentage(radioButtonValue ? "amount" : "percentage");
    if (type === "multi-select") {
      if (!inputValue) {
        setSelectAll(false);
        return;
      }
      const selectedCount = inputValue?.split(",")?.length || 0;
      if (selectedCount < selectOptions?.length) {
        setSelectAll(false);
      } else if (selectedCount === selectOptions?.length) {
        setSelectAll(true);
      }
    }
  }, [inputValue, radioButtonValue, showRadiobutton, selectOptions]);


  useEffect(() => {
    if (updateValidations) {
      if (showHint) {
        updateValidations(false);
      } else {
        updateValidations(true);
      }
    }
  }, [showHint]);

  const copyValue = (event: ChangeEvent<HTMLInputElement>) => {
    const newObj: { [key: string]: string } = {};
    newObj[valueKey] = value;
    onChangeAction && newObj[valueKey] !== inputValue && onChangeAction(newObj);
    copyInputValue(valueKey, event.target.checked);
    if (onChangeAction) {
      const isValid = !validations();
      setErrorValidation && setErrorValidation(valueKey, isValid);
      !ignoreValidation && setShowHint(isValid);
    }
  };

  const updateValue = (data?: any) => {
    const newObj: any = {};
    if (type === "multi-select") {
      newObj[valueKey] = data;
    } else if (valueKey === "payroll_date") {
      newObj[valueKey] = data;
    } else if (valueKey === "joining_date") {
      newObj[valueKey] = data;
    } else if (valueKey === "delivery_dates") {
      //to avoid the date formate
      newObj[valueKey] = data;
    } else if (valueKey === "date") {
      newObj[valueKey] = data;
    } else if (valueKey === "designations") {
      if (!value || showHint) return;
      if (
        receivedUpload
          ? receivedUpload.split(",").find((item: any) => item === value)
          : false
      ) {
        return;
      }
      newObj[valueKey] = receivedUpload ? receivedUpload + "," + value : value;
      setValue("");
      onChangeAction &&
        newObj[valueKey] !== inputValue &&
        onChangeAction(newObj);
      return;
    } else {
      newObj[valueKey] = value;
    }
    onChangeAction &&
      newObj[valueKey] !== inputValue &&
      onChangeAction(
        newObj,
        (showVerify && !readOnlyVerify) || allowVerifyUpdate ? valueKey : null,
        (showVerify && !readOnlyVerify) || allowVerifyUpdate
          ? adminVerifyKey
          : null,
        (showVerify && !readOnlyVerify) || allowVerifyUpdate
          ? checkerVerifyKey
          : null
      );
    updateOnBlur &&
      newObj[valueKey] !== inputValue &&
      onChangeAction({ [valueKey]: value });
    if (onChangeAction && !ignoreValidation) {
      const isValid = !validations();
      setErrorValidation && setErrorValidation(valueKey, isValid);
      setShowHint(isValid);
    }
  };



  const deleteFile = (
    id?: string,
    uniqueId?: string | number,
    others?: boolean
  ) => {
    const shouldDelete = id ? true : false;
    deleteUploadedFile(pathKey, id || uniqueId, shouldDelete, others);
  };

  const updateList = (value: string) => {
    const list = receivedUpload
      .split(",")
      .filter((item: any) => item !== value)
      .join(",");
    onChangeAction &&
      onChangeAction({ [valueKey]: list }, "", { value, receivedUpload });
  };

  const validations = () => {
    if (!value || value === "") {
      return true;
    }
    switch (validationType) {
      case "email":
      case "master-ep-corp-email":
      case "master-ep-email":
      case "contact_person_email":
        const emailRegEx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.]+\.+[a-zA-Z]{2,}$/; //{2,} here we need to have atleast 2 chars at the end!
        setIsEmailValid && setIsEmailValid(emailRegEx.test(value));
        return emailRegEx.test(value);
      case "password":
      case "new_password":
        const passwordRegEx =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,16}$/;
        return passwordRegEx.test(value);
      case "mobile":
      case "contact_person_mobile":
        const mobileRegEx = /^[0-9]{10}$/;
        setIsMobileValid && setIsMobileValid(mobileRegEx.test(value));
        return mobileRegEx.test(value);
      case "corporate_id":
        const corpCodeRegEx = /^[A-Za-z0-9]{12}$/;
        setIsMobileValid && setIsMobileValid(corpCodeRegEx.test(value));
        return corpCodeRegEx.test(value);
      case "user_name":
      case "spoc_name":
      case "name":
      case "master-ep-tctpladminname":
      case "master-ep-corp-spoc-name":
      case "first_name":
      case "last_name":
      case "contact_person_name":
        const nameRegex = /^[\s]*[a-zA-Z,'\-\s]{1,}[\s]*$/;
        return nameRegex.test(value);
      case "corporate_name":
      case "name_of_entity":
        return value.length > 2;
      case "registered_city":
      case "salary_bank_name":
      case "bank_name":
        const generalRegex = /^[\s]*[a-zA-Z\s]{3,}[\s]*$/;
        return generalRegex.test(value);
      case "master-ep-location":
      case "master-ep-corp-addr":
      case "present_address":
      case "address":
      case "city":
      case "permanent_address":
      case "master-ep-corp-address":
        const addressRegEx = /^[a-zA-Z0-9\s,'-/]{3,}$/;
        return addressRegEx.test(value);
      case "net_salary":
      case "advance_amount":
        return value >= 1000;
      case "bank_number":
      case "salary_bank_account_number":
      case "bank_account_number":
      case "account_no":
        return value > 99;
      case "salary_bank_ifsc":
      case "ifsc":
        const isfc = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        return isfc.test(value);
      case "employee_id":
        return value?.length >= 4;
      // case "payroll_date":
      //   if (value === 0 || value === "0") return false;
      //   if (value?.split(",")?.find((item: string) => item === "0")) {
      //     return false;
      //   }
      //   const payroll_dateRegex =
      //     /^(1?[0-9]|2[0-9]|3[0-1])(,(1?[0-9]|2[0-9]|3[0-1]))*$/;
      //   return (
      //     !value ||
      //     (payroll_dateRegex.test(value) &&
      //       value.split(",")?.length === Number(receivedUpload))
      //   );
      case "designation":
      case "spoc_designation":
      case "master-ep-designation":
      case "master-ep-corp-spoc-band":
      case "designations":
        const designationRegex = /^(?=.*[A-Za-z])[A-Za-z0-9 ]{3,}$/;
        return designationRegex.test(value);
      case "salary":
        const salaryRegex = /^(1000|[1-9]\d{3,})$/;
        return salaryRegex.test(value);
      case "vintage":
        const numberRegex = /^(?:[1-9]|[1-4]\d|5[0])$/;
        return numberRegex.test(value);
      case "master-ep-validity-years":
        const numberyearsRegex = /^(?:[1-9]|[1-9]\d|10[0])$/;
        return numberyearsRegex.test(value);
      case "advance_amount_start_range":
      case "advance_amount_end_range":
      case "application_fees":
      case "installments":
        return value >= 1;
      case "fees_amount":
      case "leasingfee_amount":
      case "gst_percentage":
      case "residual_value_percentage":
        return secondValidation ? value >= 0 : value >= 0 && value <= 100;
      case "master-ep-tctpladmin-age":
      case "master-ep-corp-spoc-age":
        const ageRegex = /\b(?:1[8-9]|[2-4][0-9]|50)\b/;
        return ageRegex.test(value);
      case "multiplier":
        const multiplierRegex = /^\d+(\.\d{1,3})?$/;
        return multiplierRegex.test(value) && value >= 0 && value <= 40;
      case "recovery_period":
        const recoveryPeriodRegex = /^(?:[1-9]|[1-5]\d|6[0])$/;
        return recoveryPeriodRegex.test(value);
      case "cap_amount":
        const capAmountRegex = /^(10000000|\d{1,7})$/;
        return capAmountRegex.test(value);
      case "pan_number":
      case "pan":
        const panNumberRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/;
        return panNumberRegex.test(value);
      case "PAN":
        let pan: { [key: string]: string } = {
          C: "Company",
          P: "Personal",
          H: "Hindu Undivided Family (HUF)",
          F: "Firm",
          A: "Association of Persons (AOP)",
          T: "AOP (Trust)",
          B: "Body of Individuals (BOI)",
          L: "Local Authority",
          J: "Artificial Juridical Person",
          G: "Govt",
        };
        const validThirdChar = pan[value[3]];
        const PANRegEX = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        return PANRegEX.test(value) && !!validThirdChar;
      case "GST":
        const GSTRegEx =
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        return GSTRegEx.test(value);
      default:
        return true;
    }
  };

  const radioActions = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPercentage(event.target.value);
    radioAction(event.target.value);
  };

  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 1;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const textFieldProps = useMemo(
    () =>
      disabled
        ? {
            InputLabelProps: {
              style: { color: "black" },
            },
            variant: variant || "outlined",
            placeholder: "DD-MMM-YYYY",
          }
        : {
            disabled: true,
            InputLabelProps: {
              style: { color: "black" },
            },
            variant: variant || "outlined",
            placeholder: "DD-MMM-YYYY",
            sx: {
              "& .Mui-disabled": {
                WebkitTextFillColor: "black !important",
              },
            },
          },
    [disabled]
  );
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      disableFocusListener={true}
      enterTouchDelay={0}
      leaveTouchDelay={3000}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      border: "1px solid black",
    },
  }));
  //#endregion

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
          "& .Mui-disabled": {
            WebkitTextFillColor: "rgba(0, 0, 0, 0.6) !important",
          },
        }}
        noValidate
        autoComplete="off"
      >
        {type === "text" && (
          <div>
            <TextField
              InputProps={{
                readOnly: readOnly,
                endAdornment: definitionText && (
                  <div
                    style={{
                      position: "absolute",
                      right: "4px",
                    }}
                  >
                    <LightTooltip
                      title={definitionText}
                      arrow
                      disableFocusListener={true}
                    >
                      <IconButton>
                        <InfoIcon className="note-icon" />
                      </IconButton>
                    </LightTooltip>
                  </div>
                ),
              }}
              sx={{
                margin: isSignupPage ? "0px !important" : "",
                "& .MuiInputBase-input": {
                  color: textColor ? textColor : "inherit",
                }, // Target the input element
              }}
              error={
                showHint ||
                externalError ||
                (isRequired && globalError && !value)
              }
              required={isRequired}
              id={`${id}-text`}
              label={label}
              placeholder={placeholder}
              variant={variant ?? "standard"}
              value={value}
              onChange={(e) => {
                if (updateOnChange) {
                  updateStateOnChange();
                }
                if (
                  valueKey === "pan_number" ||
                  valueKey === "gst_number" ||
                  valueKey === "corporate_id" ||
                  valueKey === "salary_bank_ifsc" ||
                  valueKey === "pan"
                ) {
                  setValue(e.target.value?.toUpperCase());
                }
                // else if (
                //   valueKey === "payroll_date" &&
                //   e.target.value &&
                //   !/^[0-9,]+$/.test(e.target.value)
                // ) {
                //   return;
                // }
                else if (isNumberType && isNaN(Number(e.target.value))) {
                  return null;
                } else if (
                  valueKey === "employee_count" &&
                  Number(e.target.value) === 0
                ) {
                  setValue(null);
                } else setValue(e.target.value);
              }}
              onBlur={updateValue}
              disabled={disabled}
              helperText={
                showHint || externalError
                  ? helperText || "Minimum 3 characters are required"
                  : null
              }
              InputLabelProps={{
                style: {
                  color:
                    showHint ||
                    externalError ||
                    (isRequired && globalError && !value)
                      ? "#d32f2f"
                      : disabled
                      ? "rgb(0, 0, 0, 0.6)"
                      : "black",
                },
              }}
            />
            {fieldNote ? (
              <span
                className="field-note"
                style={{
                  marginTop: "-6px",
                }}
              >
                {hideInfoIcon ? null : <InfoIcon className="note-icon" />}
                {fieldNote}
              </span>
            ) : null}
            {showCopyCheckbox ? (
              <span className="copy-checkbox">
                <Checkbox
                  id="copy-checkbox"
                  className="checkbox"
                  checked={copyCheckboxClicked}
                  onChange={(e: any) => {
                    if (allowToEditCheckBox) {
                      copyValue(e);
                    }
                  }}
                />
                <label htmlFor="copy-checkbox" className="cursor-pointer">
                  {checkboxLabel}
                </label>
              </span>
            ) : null}
            {showRadiobutton ? (
              <FormControl className="radio-input" component="fieldset">
                <RadioGroup
                  aria-label="Charges type"
                  name="Charges type"
                  value={isPercentage}
                  onChange={radioActions}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FormLabel sx={{ fontSize: "15px", color: "black" }}>
                    Charges Type :
                  </FormLabel>
                  <FormControlLabel
                    value="percentage"
                    control={<Radio />}
                    label="Interest %"
                    sx={{
                      marginLeft: "0px",
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                      },
                    }}
                  />
                  <FormControlLabel
                    value="amount"
                    control={<Radio />}
                    label="Fees Amount"
                    sx={{
                      marginLeft: "-15px",
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
          </div>
        )}
      </Box>
    </>
  );
};

export default Input;
